import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseService } from "./base.service";
import { CountryLabelPO, PrismSeedTreatment, STiRResultListPO, STiRResult, STiRResultPO, MMTreatmentGroupPO, MMTreatmentSavePO, MMTreatmentGroupDeletePO } from "../models";
import { AppConstants } from "../shared/constants";

@Injectable({
    providedIn: "root",
})
export class MMTreatmentGroupService {

    constructor(private baseService: BaseService) { }

    GetIngredientMMTreatmenGroupsMasterData(): Observable<STiRResultListPO<MMTreatmentGroupPO>> {
        let url = "Admin/GetIngredientMMTreatmenGroupsMasterData";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    GetIngredientMMTreatmenGroups(): Observable<STiRResultListPO<MMTreatmentSavePO>> {
        let url = "Admin/GetIngredientMMTreatmenGroups";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    UpsertIngredientMMTreatmenGroups(data: MMTreatmentSavePO[]): Observable<STiRResultListPO<MMTreatmentSavePO>> {
        let url = `Admin/UpsertIngredientMMTreatmenGroups`;
        return this.baseService.Post(url, data, AppConstants.Saving);
    }

    deleteIngredientMMTreatmenGroups(data: MMTreatmentGroupDeletePO): Observable<STiRResultListPO<MMTreatmentSavePO>> {
        let url = "Admin/DeleteIngredientMMTreatmenGroups";
        return this.baseService.Post(url, data, AppConstants.Loading);
    }
}