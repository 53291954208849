import { Injectable } from "@angular/core";
import { AllTypesDeletePO, AllTypesSavePO, CropsPO, IngredientsData, IngredientsPO, RecipeInfoPO, STiRResultListPO, STiRResultPO, TreatmentPO, UserClaim } from "../models";
import { MsalService } from "@azure/msal-angular";
import { AppConstants } from "../shared/constants";
import { BaseService } from "./base.service";
import { Observable } from "rxjs";
import { StationsPO, IAllTypesPO, IGridPreference } from "../models";
import { DecimalPipe } from "@angular/common";

@Injectable({
    providedIn: "root",
})
export class SharedService {

    claims: UserClaim = new UserClaim();
    roles: string[] = [];
    constructor(private msalService: MsalService, private baseService: BaseService,
        private decimalPipe: DecimalPipe
    ) {
        let allAccounts = this.msalService.instance.getAllAccounts()
        if (allAccounts.length > 0) {
            let account = allAccounts[0];
            this.roles = account.idTokenClaims!.roles;
        }

    }

    setSessionStorageValue(claims: UserClaim) {
        this.claims = claims
    }

    getSessionStorageValue(): any {
        return this.claims;
    }

    clearGridFilters(gridApi: any) {
        gridApi.setFilterModel(null);
    }

    hasAdmin(): boolean {
        return this.roles.includes(AppConstants.STiR_Admin_Role);
    }

    hasUser(): boolean {
        return this.roles.includes(AppConstants.STiR_User_Role);
    }


    fetchCountries(): Observable<StationsPO[]> {
        let url = "Common/GetCountries";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    fetchAllTypes(): Observable<IAllTypesPO[]> {
        let url = "AllType/GetTypes";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    fetchAllTypesDetails(): Observable<IAllTypesPO[]> {
        let url = "AllType/FetchAllTypesDetails";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    upsertAllTypesDetails(data: AllTypesSavePO): Observable<STiRResultListPO<IAllTypesPO>> {
        let url = "Admin/UpsertAllTypesDetails";
        return this.baseService.Post(url, data, AppConstants.Loading);
    }

    deleteAllTypesDetails(data: AllTypesDeletePO): Observable<STiRResultListPO<IAllTypesPO>> {
        let url = "Admin/DeleteAllTypesDetails";
        return this.baseService.Post(url, data, AppConstants.Loading);
    }

    async getAllTypes(types: string[]): Promise<IAllTypesPO[]> {
        let url = 'AllType/GetTypesDetails';
        const res = await this.baseService.awaitPost(url, types, AppConstants.Loading);
        return res;
    }

    fetchCrops(): Observable<CropsPO[]> {
        let url = "Common/GetCrops";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    fetchTreatmentsNotUsedInRecipes(): Observable<TreatmentPO[]> {
        let url = "Common/FetchTreatmentsNotUsedInRecipes";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    getGridPreferences(data: IGridPreference): Observable<any> {
        let url = "Common/GetGridPreferences";
        return this.baseService.Post(url, data, AppConstants.Loading);
    }

    fetchGetSeedsPerGram(): Observable<STiRResultPO<string>> {
        let url = "Common/GetSeedsPerGram";
        return this.baseService.Get(url, AppConstants.Loading);
    }

    saveGridPreferences(data: IGridPreference): Observable<any> {
        let url = 'Common/SaveGridPreferences'
        return this.baseService.Post(url, data, AppConstants.Saving);
    }

    getIngredients(): Observable<STiRResultPO<IngredientsData>> {
        let url = 'Common/GetIngredients'
        return this.baseService.Get(url, AppConstants.Loading);
    }

    getAuditHistory(method: string, id: string): any {
        let url = 'Common/' + method;
        return this.baseService.GetByID(url, id);
    }

    getRecipesForOvertreat(cropCodes: string[]): Observable<STiRResultListPO<RecipeInfoPO>> {
        let url = 'Common/GetRecipesForOvertreat'
        return this.baseService.Post(url, cropCodes, AppConstants.Loading);
    }

    getSAPBatchIngredientDetails(batchId: string): Observable<STiRResultListPO<IngredientsPO>> {
        let url = `Common/GetSAPBatchIngredientDetails?batchId=${batchId}`
        return this.baseService.Get(url, AppConstants.Loading);
    }

    hasDuplicatesByProperty(array: any[], property: keyof any): boolean {
        const seen = new Set();
        for (const item of array) {
            if (item[property] !== null && seen.has(item[property].toLowerCase())) {
                return true;
            }
            if(item[property] !== null && item[property].trim() !== '')
            seen.add(item[property].toLowerCase());
        }
        return false;
    }

    hasDuplicatesByCombination(array: any[], properties: (keyof any)[]): boolean {
        const seen = new Set();
        for (const item of array) {
            const key = properties.map(prop => item[prop]).join('|');
            if (seen.has(key)) {
                return true;
            }
            seen.add(key);
        }
        return false;
    }

    getCustomDecimalsAfterDot(value: any, format: string = '1.1-4') {
        return parseFloat(this.decimalPipe.transform(value, '1.1-4').replace(/,/g, ''));
    }
}